<template>
  <div class="aali_tm_section help" id="portfolio">
    <div class="aali_tm_portfolio wow fadeInUp" data-wow-duration="1s">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="light"
        >
          <span>Selected works</span>
          <h3>{{ $t("open_portfolio") }}</h3>
        </div>
        <div class="portfolio_filter">
          <ul>
            <li><a href="#">HTML5</a></li>
            <li><a href="#">CSS3</a></li>
            <li><a href="#">RWD</a></li>
            <li><a href="#">UX</a></li>
            <li><a href="#">Ajax</a></li>
            <li><a href="#">Vue3.js</a></li>
          </ul>
          <ul>
            <li><a href="#">JQuery</a></li>
            <li><a href="#">Git.</a></li>
            <li><a href="#">ASP.NET</a></li>
            <li><a href="#">.NET C#</a></li>
            <li><a href="#">.NET Core</a></li>
          </ul>
          <ul>
            <li><a href="#">Python</a></li>
            <li><a href="#">MSSQL</a></li>
            <li><a href="#">MySQL</a></li>
            <li><a href="#">IIS</a></li>
          </ul>
        </div>
        <div class="portfolio_list">
          <ul class="gallery_zoom">
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/topspeed.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Front End Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="#"
                  @click.prevent="
                    () => onClick('img', 'img/portfolio/topspeed.png')
                  "
                ></a>
              </div>
            </li>
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/FST.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Full Stack Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="#"
                  @click.prevent="() => onClick('img', 'img/portfolio/FST.png')"
                ></a>
              </div>
            </li>
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/FHI.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Full Stack Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="#"
                  @click.prevent="() => onClick('img', 'img/portfolio/FHI.png')"
                ></a>
              </div>
            </li>
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/ITEC.png"></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Full Stack Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="#"
                  @click.prevent="
                    () => onClick('img', 'img/portfolio/ITEC.png')
                  "
                ></a>
              </div>
            </li>

            <div :class="`${active === 2 ? '' : 'hidden_content'}`">
              <ModalBox :close="close">
                <div class="popup_details">
                  <div class="top_image">
                    <img src="img/thumbs/4-2.jpg" alt="" />
                    <div
                      class="main"
                      data-img-url="img/portfolio/6.jpg"
                      style="background-image: url('img/portfolio/6.jpg')"
                    ></div>
                  </div>
                  <div class="portfolio_main_title">
                    <span>Detail</span>
                    <h3>Good Present</h3>
                    <div></div>
                  </div>
                  <div class="main_details">
                    <div class="textbox">
                      <p>
                        We live in a world where we need to move quickly and
                        iterate on our ideas as flexibly as possible. Building
                        mockups strikes the ideal balance ease of modification.
                      </p>
                      <p>
                        Mockups are useful both for the creative phase of the
                        project - for instance when you're trying to figure out
                        your user flows or the proper visual hierarchy - and the
                        production phase when they will represent the target
                        product.
                      </p>
                    </div>
                    <div class="detailbox">
                      <ul>
                        <li>
                          <span class="first">Client</span>
                          <span>Alvaro Morata</span>
                        </li>
                        <li>
                          <span class="first">Category</span>
                          <span><a href="#">Detail</a></span>
                        </li>
                        <li>
                          <span class="first">Date</span>
                          <span>March 07, 2021</span>
                        </li>
                        <li>
                          <span class="first">Share</span>
                          <ul class="share">
                            <li>
                              <a href="#"
                                ><img
                                  class="svg"
                                  src="img/svg/social/facebook.svg"
                                  alt=""
                              /></a>
                            </li>
                            <li>
                              <a href="#"
                                ><img
                                  class="svg"
                                  src="img/svg/social/twitter.svg"
                                  alt=""
                              /></a>
                            </li>
                            <li>
                              <a href="#"
                                ><img
                                  class="svg"
                                  src="img/svg/social/instagram.svg"
                                  alt=""
                              /></a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="additional_images">
                    <ul>
                      <li>
                        <div class="list_inner">
                          <div class="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="" />
                            <div
                              class="main"
                              data-img-url="img/portfolio/1.jpg"
                              style="
                                background-image: url('img/portfolio/1.jpg');
                              "
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="list_inner">
                          <div class="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="" />
                            <div
                              class="main"
                              data-img-url="img/portfolio/2.jpg"
                              style="
                                background-image: url('img/portfolio/2.jpg');
                              "
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="list_inner">
                          <div class="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="" />
                            <div
                              class="main"
                              data-img-url="img/portfolio/3.jpg"
                              style="
                                background-image: url('img/portfolio/3.jpg');
                              "
                            ></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </ModalBox>
            </div>
            -->
          </ul>
        </div>
      </div>
    </div>
    <MagnificPopUp :modal="modal" :close="closeModal" :name="name" :src="src" />
  </div>
</template>

<script>
import MagnificPopUp from "./MagnificPopUp.vue";
import ModalBox from "./ModalBox.vue";
import { maximize, soundcloud, text, vimeo, youtube } from "./svgImage";
export default {
  name: "Portfolio",
  data() {
    return {
      vimeo,
      youtube,
      soundcloud,
      text,
      maximize,
      // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
    };
  },
  mounted() {
    let Isotope = require("isotope-layout");
    setTimeout(() => {
      let iso = new Isotope(".gallery_zoom", {
        itemSelector: ".gallery_item",
        layoutMode: "fitRows",
      });
      let filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function (itemElem) {
          let number = itemElem.querySelector(".number").textContent;
          return parseInt(number, 10) > 50;
        },
        // show if name ends with -ium
        ium: function (itemElem) {
          let name = itemElem.querySelector(".name").textContent;
          return name.match(/ium$/);
        },
      };
      // bind filter button click filter main funtion
      let filtersElem = document.querySelector(".portfolio_filter ul");
      filtersElem.addEventListener("click", function (event) {
        if (event.target.getAttribute("data-filter")) {
          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        }
      });
    }, 1000);
  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      this.active = 0;
    },
  },
  components: { MagnificPopUp, ModalBox },
};
</script>
