<template>
  <div class="aali_tm_section" id="resume">
    <div class="aali_tm_resume">
      <div class="content">
        <div class="container">
          <div class="resume_in">
            <div
              class="aali_tm_main_title"
              data-text-align="left"
              data-color="dark"
            >
              <span>{{ $t("know_more") }}</span>
              <h3>{{ $t("know_more_edu_exp") }}</h3>
              <p>
                {{ $t("resume_description") }}
              </p>
            </div>
            <div class="content_inner">
              <ul>
                <li class="wow fadeInLeft" data-wow-duration="1s">
                  <h3 class="main_title">
                    <span>{{ $t("education") }}</span>
                  </h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>{{ $t("high_school") }}</h3>
                            <span>{{ $t("high_school_name") }}</span>
                          </div>
                          <div class="right">
                            <span>2008 - 2011</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            {{ $t("high_school_description") }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>{{ $t("bachelor_degree") }}</h3>
                            <span>{{ $t("bachelor_university") }}</span>
                          </div>
                          <div class="right">
                            <span>2011 - 2015</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            {{ $t("bachelor_university_description") }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>{{ $t("master_degree") }}</h3>
                            <span>{{ $t("master_university") }}</span>
                          </div>
                          <div class="right">
                            <span>2012 - 2014</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>{{ $t("master_university_description") }}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="wow fadeInRight" data-wow-duration="1s">
                  <h3 class="main_title">
                    <span>{{ $t("experience") }}</span>
                  </h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>{{ $t("research_assistant") }}</h3>
                            <span>{{ $t("CMUH") }}</span>
                          </div>
                          <div class="right">
                            <span>2017/11 - 2018/1</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            {{ $t("research_assistant_work_detail") }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>{{ $t("front_end_developer") }}</h3>
                            <span>{{ $t("threehope_ltd_co") }}</span>
                          </div>
                          <div class="right">
                            <span>2018/2 - 2018/10</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            {{ $t("front_end_develop_work_detail") }}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>{{ $t("full_stack_develop_fit_pm") }}</h3>
                            <span>{{ $t("fpc") }}</span>
                          </div>
                          <div class="right">
                            <span>2019 - NOW</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            {{ $t("full_stack_develop_fit_pm_work_detail") }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="media" data-background-style="video">
        <!-- Background Styles: "video" and "image" // Also you can use any youtube, vimeo, and local videos -->
        <div
          class="video jarallax"
          data-speed="0"
          data-jarallax-video="https://vimeo.com/337293658"
        ></div>
        <div
          class="image jarallax"
          data-speed="0"
          data-img-url="img/about/2.jpg"
        ></div>
        <span
          class="square moving_effect"
          data-direction="y"
          data-reverse="yes"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { jarallaxContent } from "../utils/utils";
import { edu, exp } from "./svgImage";

export default {
  name: "Resume",
  data() {
    return {
      edu,
      exp,
    };
  },
  mounted() {
    jarallaxContent();
  },
};
</script>
