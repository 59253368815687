<template>
  <div class="aali_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <a class="light" href="#"
            ><img src="img/logo/jologo-light.png" alt=""
          /></a>
          <a class="dark" href="#"
            ><img
              :src="
                dark ? 'img/logo/jologo-light.png' : 'img/logo/jologo-dark.png'
              "
              alt=""
          /></a>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current">
              <a href="#home">{{ $t("home") }}</a>
            </li>
            <li>
              <a href="#about">{{ $t("about") }}</a>
            </li>
            <li>
              <a href="#skills">{{ $t("skills") }}</a>
            </li>
            <li>
              <a href="#portfolio">{{ $t("portfolio") }}</a>
            </li>
            <li>
              <a href="#service">{{ $t("field") }}</a>
            </li>
            <li>
              <a href="#resume">{{ $t("eduexp") }}</a>
            </li>
            <li>
              <a href="#life">{{ $t("life") }}</a>
            </li>
            <li>
              <a href="#contact">{{ $t("get_in_touch") }}</a>
            </li>
            <li>
              <!-- 語言切換按鈕 -->
              <a href="#" class="btn_lang" @click.prevent="switchLanguage">{{
                buttonLabel
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scroll_, stickyNav } from "../../utils/utils";

export default {
  name: "Header",
  props: {
    dark: Boolean,
  },
  computed: {
    // 根據當前語言動態顯示按鈕的文字
    buttonLabel() {
      return this.$i18n.locale === "zh" ? "EN" : "繁";
    },
  },
  methods: {
    switchLanguage() {
      // 切換語言：中文 -> 英文，英文 -> 中文
      this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
      // 儲存當前語言到 cookie
      document.cookie = `j_cookie_lang=${this.$i18n.locale}; path=/; max-age=31536000`; // 設置有效期為一年
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return null;
    },
    initializeLanguage() {
      // 從 cookie 讀取語言設定
      const savedLang = this.getCookie("j_cookie_lang");
      if (savedLang) {
        this.$i18n.locale = savedLang;
      }
    },
  },
  mounted() {
    this.initializeLanguage(); // 初始化語言設定
    window.addEventListener("scroll", scroll_);
    window.addEventListener("scroll", stickyNav);
  },
};
</script>
