<template>
  <div class="aali_tm_section" id="service">
    <div class="aali_tm_service">
      <div class="container">
        <div class="service_list">
          <ul>
            <li class="simple wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner">
                <div
                  class="aali_tm_main_title"
                  data-text-align="left"
                  data-color="dark"
                >
                  <span>{{ $t("my_capability") }}</span>
                  <h3>{{ $t("professional_field") }}</h3>
                  <p>
                    {{ $t("professional_field_ability") }}
                  </p>
                </div>
              </div>
            </li>

            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/new/pen.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>

                <div class="title">
                  <h3>{{ $t("project_management") }}</h3>
                  <span class="exp"
                    ><span>2</span> {{ $t("years_experience") }}</span
                  >
                </div>
                <div class="text">
                  <p>
                    {{ $t("project_management_detail") }}
                  </p>
                </div>
                <img
                  class="popup_service_image"
                  src="img/service/1.jpg"
                  alt=""
                />
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/new/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>{{ $t("website_develop") }}</h3>
                  <span class="exp"
                    ><span>5</span> {{ $t("years_experience") }}</span
                  >
                </div>
                <div class="text">
                  <p>
                    {{ $t("website_develop_detail") }}
                  </p>
                </div>
                <img
                  class="popup_service_image"
                  src="img/service/3.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="img/svg/new/user_security.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>{{ $t("information_security") }}</h3>
                  <span class="exp"
                    ><span>3</span> {{ $t("years_experience") }}</span
                  >
                </div>
                <div class="text">
                  <p>
                    {{ $t("information_security_detail") }}
                  </p>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="img/svg/new/user2.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>{{ $t("extremely_communicative") }}</h3>
                </div>
                <div class="text">
                  <p>
                    {{ $t("extremely_communicative_detail") }}
                  </p>
                </div>
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="img/svg/new/design.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>{{ $t("adaptive_flexible") }}</h3>
                </div>
                <div class="text">
                  <p>
                    {{ $t("adaptive_flexible_detail") }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <span
        class="square_left moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
      <span
        class="square_right moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
// import ModalBox from "./ModalBox.vue";
import { msg } from "./svgImage";
export default {
  name: "Service",
  data() {
    return {
      msg,
      active: 0,
    };
  },
  props: {
    dark: Boolean,
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  // components: { ModalBox },
};
</script>
