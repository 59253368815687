<template>
  <div class="aali_tm_section" id="about">
    <div class="aali_tm_about">
      <div class="about_inner">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/45-49.jpg" alt="" />
            <div class="main" data-img-url="img/about/KingStation.png"></div>
            <span
              class="square moving_effect"
              data-direction="y"
              data-reverse="yes"
            ></span>
            <span class="border"
              ><span
                class="in moving_effect"
                data-direction="x"
                data-reverse="yes"
              ></span
            ></span>
          </div>
        </div>
        <div class="center">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <span class="small">{{ $t("aboutme") }}</span>
            <h3>I'm <span>Joy</span></h3>
            <h3>{{ $t("web_tt") }} <br><span>{{ $t("developer") }}</span></h3>
            <span class="subtitle">Based in Taiwan.</span>
          </div>
          <div
            class="text wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <p>
              {{ $t("basic_about_intro") }}
            </p>
            <p>
              {{ $t("basic_about_intro_detail") }}
            </p>
          </div>
          <!-- <div
            class="aali_tm_button wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
          >
            <a href="files/Joy Sun Resume.pdf" download>
              <span v-html="download"> </span
            ></a>
          </div> -->
        </div>
        <div class="right">
          <div class="image">
            <img src="img/thumbs/41-61.jpg" alt="" />
            <div class="main" data-img-url="img/about/Me.jpg"></div>
            <div
              class="square moving_effect"
              data-direction="x"
              data-reverse="no"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "./svgImage";
export default {
  name: "About",
  data() {
    return {
      download: `Download Resume ${download}`,
    };
  },
};
</script>
