<template>
  <div class="aali_tm_section" id="life">
    <div class="aali_tm_counter">
      <div class="container">
        <div class="counter_list">
          <div class="wrapper">
            <div class="text" style="padding-right: 45px">
              <h3>Passionate</h3>
            </div>
            <div class="value">
              <span>{{ $t("my_real_life") }}</span>
            </div>
          </div>
          <div class="swiper-container">
            <swiper :options="swiperOptions" ref="mySwiper">
              <swiper-slide v-for="(image, index) in images" :key="index">
                <img :src="image" alt="Slide image" />
              </swiper-slide>
            </swiper>
          </div>
          <div class="aali_tm_testimonials" style="padding: 0">
            <div class="direct">
              <a class="prev_button" href="#"><i class="icon-left"></i></a>
              <a class="next_button" href="#"><i class="icon-right"></i></a>
            </div>
          </div>
        </div>
      </div>
      <span class="border moving_effect" data-direction="x"></span>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// import VisibilitySensor from "vue-visibility-sensor";
export default {
  name: "Counter",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const requireImages = require.context(
      "@/assets/img/life",
      false,
      /\.(jpg|jpeg|png|gif)$/
    );
    const images = requireImages.keys().map(requireImages);
    
    return {
      images: images,
      swiperOptions: {
        // lazy: false, // 禁用懶加載
        loop: true, // 設置自動播放、循環等配置
        spaceBetween: 0, // 設定圖片之間的間距
        centeredSlides: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          prevEl: ".prev_button",
          nextEl: ".next_button",
        },
        speed: 900, // Transition duration in milliseconds
        easing: "ease-in-out", // Smooth easing function
        breakpoints: {
          768: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>
<style scoped>
.swiper-container {
  width: 100%;
  height: 380px;
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.swiper-slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0.8;
  transform: scale(0.8);
}
.swiper-slide-active {
  opacity: 1;
  transform: scale(1);
}
</style>
