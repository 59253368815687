<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="dark"
        >
          <span>{{ $t("get_in_touch") }}</span>
          <h3>{{ $t("send_mail_to_me") }}</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <p>
                {{ $t("contact_text_detail") }}
              </p>
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    `<span>Call me</span>
                  <h3><a class='line_anim' href='tel:+886 928955131'>+886 928955131</a></h3>` +
                    call
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:suj811014@gmail.com'
                      >suj811014@gmail.com</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                class="contact_form"
                id="contact_form"
                @submit.prevent="handleSubmit"
              >
                <div
                  class="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                  :style="{
                    display: success ? 'block' : 'none',
                  }"
                >
                  <span class="contact_success">
                    Your message has been received, We will contact you soon.
                  </span>
                </div>
                <div
                  class="empty_notice"
                  :style="{ display: error ? 'block' : 'none' }"
                >
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        type="text"
                        v-model="fromData.name"
                        :placeholder="$t('placeholders.name')"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="text"
                        v-model="fromData.email"
                        :placeholder="$t('placeholders.email')"
                      />
                    </li>
                    <li>
                      <input
                        id="phone"
                        type="number"
                        :placeholder="$t('placeholders.phone')"
                        name="phn"
                        v-model="fromData.phone"
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    v-model="fromData.msg"
                    :placeholder="$t('placeholders.message_content')"
                  ></textarea>
                </div>
                <div class="aali_tm_button">
                  <a id="send_message" href="#" @click.prevent="handleSubmit"
                    ><span v-html="$t('placeholders.message_send') + msgSent">
                    </span
                  ></a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import { call, msg, msgSent } from "./svgImage";
import emailjs from "emailjs-com";
export default {
  name: "Contact",
  data() {
    return {
      msgSent,
      call,
      msg,
      fromData: {
        name: "",
        email: "",
        phone: "",
        msg: "",
      },
      error: false,
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      const { name, email, phone, msg } = this.fromData;
      const prefix = "T-",
        suffix = "6Sh0cK9ita2pXe4",
        // randomPart = Math.random().toString(36).substring(2, 10),
        service = "yu37ua1",
        template = "ux0v4iz";
      const sid = `service_${service}`,
        tpid = `template_${template}`,
        uid = `${prefix}${suffix}`;
      if (name && email && phone && msg) {
        const emailParams = {
          from_name: name,
          from_email: email,
          phone: phone,
          message: msg,
        };

        emailjs
          .send(sid, tpid, emailParams, uid)
          .then((response) => {
            console.log("Email sent successfully", response);
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 2000);
            alert("Email sent successfully!"); // 顯示成功訊息
          })
          .catch((error) => {
            console.error("Error sending email", error);
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 2000);
          });
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },
  },
};
</script>
