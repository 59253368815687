<template>
  <div class="aali_tm_section">
    <div class="aali_tm_copyright">
      <div class="container">
        <div class="copyright_inner">
          <div class="aali_tm_down footer_totop">
            <div class="down-icon white top_120">
              <a class="anchor" href="#home">
                <svg
                  width="26px"
                  height="100%"
                  viewBox="0 0 247 390"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 1.5;
                  "
                >
                  <path
                    id="wheel"
                    d="M123.359,79.775l0,72.843"
                    style="fill: none; stroke: #fff; stroke-width: 20px"
                  />
                  <path
                    id="mouse"
                    d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
                    style="fill: none; stroke: #fff; stroke-width: 20px"
                  />
                </svg>
              </a>
            </div>
            <p>TO TOP</p>
          </div>
          <div class="top">
            <div class="logo">
              <img src="img/logo/jologo-light.png" alt="" />
            </div>
            <div class="social">
              <span>Connect</span>
              <ul>
                <li>
                  <a
                    href="files/Joy Sun Resume.pdf"
                    target="_blank"
                    title="my resume"
                    ><i class="icon-newspaper-1"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/suj811014/"
                    ><i class="icon-facebook-1"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/chiao_ascx/"
                    ><i class="icon-instagram-3"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom">
            <div class="left">
              <p>
                Designed with enthusiasm by
                <a
                  class="line_anim"
                  href="https://themeforest.net/user/codeefly"
                  target="_blank"
                  >Joy</a
                >
                &copy; {{ new Date().getFullYear() }}
              </p>
            </div>
            <div class="right">
              <ul>
                <!-- <li>
                  <router-link to="/growth" class="line_anim">{{
                    $t("about_my_growth")
                  }}</router-link>
                </li> -->
                <li>
                  <a class="line_anim" href="mailto:suj811014@gmail.com">{{
                    $t("email_me")
                  }}</a>
                </li>
                <li>
                  <a class="line_anim" href="tel:+886928955131">{{
                    $t("call_me")
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <span
            class="border moving_effect"
            data-direction="y"
            data-reverse="yes"
          ></span>
        </div>
      </div>
      <span class="square moving_effect" data-direction="x"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
